/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { createReviewRequestUrl, updateReviewRequestUrl } from 'services/Settings';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { Controller, useForm } from 'react-hook-form';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { ModalHeader } from 'shared/ModalHeader';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { Calendar } from 'primereact/calendar';
import moment from 'moment-timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { copyFileUrl } from 'services/fileManager/fileManagerServices';
import { convertToTimezoneFormat, convertToTimezoneFormatEndOfDay } from 'utils/utility_functions/timezone';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

export const FileSharingClientDetailsModal = ({ show, setShow, onHide, fileDetails, rowData }) => {
  const { addToast } = useToast();
  const { userDetails } = useUserDetailsContext();
  const { handleGetFiles, pageLimit } = useFileManagerContext();

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm({ defaultValues: { nickname: '', lawft_link_expiry: '' } });

  const clearFormData = () => {
    reset({ nickname: '', lawft_link_expiry: '' });
  };

  let timezoneBasedTime = new Date().toLocaleString('en-US', {
    timeZone: userDetails?.userDetails?.timezone,
  });

  const onSubmit = async (formData) => {
    const data = {
      files_shared: [
        {
          file_system_sk: rowData?.sk,
          file_type: rowData?.file_type,
          file_name: rowData?.file_name,
          file_size: rowData?.size,
        },
      ],
      nickname: formData?.nickname,
      lawft_link_expiry: formData?.lawft_link_expiry
        ? convertToTimezoneFormatEndOfDay(formData.lawft_link_expiry, userDetails?.timezone)
        : '',
    };
    try {
      const response = await copyFileUrl({ data });
      const lawftLink = response?.data?.lawft_link;

      if (lawftLink) {
        try {
          await navigator.clipboard.writeText(lawftLink);
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
          setShow();
          clearFormData();
          handleGetFiles(pageLimit);
        } catch (clipboardError) {
          manualCopyFallback(lawftLink);
          // addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CLIPBOARD_COPY_FAIL);
        }
      }
      //  else {
      //   addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CLIPBOARD_COPY_FAIL);
      // }
    } catch (error) {
      console.error('API call failed:', error);
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CLIPBOARD_COPY_FAIL);
    }
  };

  const manualCopyFallback = (lawftLink) => {
    const tempInput = document.createElement('input');
    tempInput.value = lawftLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    try {
      document.execCommand('copy');
      //   setPopMessageVisible(true);
      //   setTimeout(() => setPopMessageVisible(false), 1000);
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
      setShow();
      clearFormData();
      handleGetFiles(pageLimit);
    } catch (execCommandError) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CLIPBOARD_COPY_FAIL);
    }
    document.body.removeChild(tempInput);
  };

  return (
    <>
      <Dialog
        header={ModalHeader}
        closable={false}
        visible={show}
        modal
        style={{ width: '30vw' }}
        onHide={() => {
          onHide();
          reset({ nickname: '', lawft_link_expiry: '' });
        }}
        resizable={false}
        className=" create-msg-popup"
      >
        <GlobalLoader />
        <form>
          <div className="d-flex justify-content-between align-items-center mt-3  mb-3">
            <div className="modal-heading F-size16 text-bold">{'Lawft Link Details'}</div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-lg-3 col-12">
              <label className="p-medium">
                Nickname
                {/* <span className="text-danger">*</span>{' '} */}
              </label>
            </div>
            <div className="col-lg-9 col-12">
              <Controller
                name="nickname"
                control={control}
                // rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextSnippetWrapper type="text" className="input-shadow w-100 border-0 p-2" {...field} placeholder="Enter Nickname" />
                )}
              />
              {/* {errors?.nickname && <span className="text-danger">Required</span>} */}
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-lg-3 col-12">
              <label className="p-medium">
                Expires on
                {/* <span className="text-danger">*</span>{' '} */}
              </label>
            </div>
            <div className="col-lg-9 col-12">
              <Controller
                name="lawft_link_expiry"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <Calendar
                    id={field.name}
                    name="lawft_link_expiry"
                    //   value={moment(field.value)?.tz(userDetails?.timezone)?.toDate()}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                      // changeTimeValues(e.value);
                      // trigger('when');
                    }}
                    minDate={new Date(timezoneBasedTime)}
                    placeholder="Select"
                    monthNavigator
                    yearNavigator
                    yearRange="1950:2050"
                    className="input-shadow w-100"
                  />
                )}
              />
              {/* {errors?.lawft_link_expiry && <span className="text-danger">Required</span>} */}
            </div>
          </div>
        </form>
        <div className="footer-btn">
          <Button
            className="p-button-secondary outline mx-2"
            label="Cancel"
            onClick={() => {
              onHide();
              reset({ nickname: '', lawft_link_expiry: '' });
            }}
          ></Button>
          <Button label={'Create & Copy Link '} className="p-button p-button-secondary" onClick={handleSubmit(onSubmit)} />
        </div>
      </Dialog>
    </>
  );
};
