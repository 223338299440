import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, Link } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { appVersion } from 'config/app_version';
import logo from 'assets/images/logo_lawft_transparent.png';
import { saveNpsScore } from 'services/nps/npsService';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

export default function NpsForm() {
  const [addedRating, setAddedRating] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { handleSubmit, control } = useForm();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { addToast } = useToast();
  const ratingLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const onSubmit = async (data) => {
    const token = queryParams?.get('token') || '';
    if (data) {
      try {
        const response = await saveNpsScore({ token, data });
        if (response?.data) {
          setAddedRating(true);
          setHasSubmitted(response?.data?.is_submitted);
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.NPS_SETTINGS_ADDED);
        }
      } catch (error) {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.NPS_SCORE_SAVE_FAILED);
      }
    }
  };

  return (
    <div>
      <GlobalLoader />
      <header className="d-flex justify-content-between align-items-center mb-3 mt-3">
        <Link to="/" className="text-dark fw-bold navbar-brand logo-wrap py-0 d-flex align-items-center">
          <img src={logo} alt="logo" width="88" height="18" />
          <label htmlFor="timezone" className="ms-2 version">
            v{appVersion}
          </label>
        </Link>
      </header>

      {!addedRating && !hasSubmitted ? (
        <div
          className="container-fluid d-flex align-items-center justify-content-center height-full"
          style={{ flexDirection: 'column', height: '80vh' }}
        >
          <Card className="b-radius16 px-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="align-items-center p-0 mb-3 pb-3 border-bottom-dark">
                  <div className="col-md-12 col-12 d-flex justify-content-center mx-2 mb-3 F-size16 p-medium">
                    <span>How likely are you to recommend our law firm to your friend or collegue?</span>
                  </div>
                  <div className="col-md-12 col-12">
                    <Controller
                      name="nps_rating"
                      control={control}
                      render={({ field }) => (
                        <div className="d-flex justify-content-center mx-2 sm-gap-3 gap-8">
                          {ratingLevels?.map((num) => (
                            <div>
                              <span key={num} className="d-flex align-items-center gap-1">
                                <button
                                  type="button"
                                  key={num}
                                  className={`rating-button ${field.value === num ? 'active' : ''}`}
                                  onClick={() => field.onChange(num)}
                                >
                                  {num}
                                </button>
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                    <div
                      className="d-flex mt-2 justify-content-between black-500"
                      style={{ marginTop: '10px', maxWidth: '500px', margin: '0 auto', fontSize: '13px' }}
                    >
                      <span>Least Likely</span>
                      <span>Most Likely</span>
                    </div>
                  </div>
                </div>
                <div className="align-items-center p-0 mt-2">
                  <div className="col-md-12 col-12 d-flex justify-content-center mx-2 mb-3 F-size16 p-medium">
                    What is the primary reason for the score you gave us?
                  </div>
                  <div className="col-md-12 col-12">
                    <Controller
                      name="feedback"
                      control={control}
                      render={({ field }) => (
                        <TextSnippetWrapper
                          type="textarea"
                          rows={10}
                          cols={20}
                          value={field.value}
                          placeholder=""
                          onChange={(e) => field.onChange(e.target.value)}
                          className="input-shadow w-100"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-end px-0 mt-3">
                  <Button type="submit" className="p-button-secondary" disabled={queryParams?.get('isPreview') === 'true' || false}>
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </Card>
        </div>
      ) : (
        <div
          className="container-fluid d-flex align-items-center justify-content-center"
          style={{ flexDirection: 'column', height: '80vh' }}
        >
          <Card className="b-radius16 px-5">
            <div className="row">
              <div className="align-items-center p-0 mb-3">
                <div className="col-md-12 col-12 d-flex justify-content-center mx-2 mb-3 F-size16">
                  <span>Thank you for your response !</span>
                </div>
              </div>
              <div className="align-items-center p-0 mt-2">
                <div className="col-md-12 col-12 d-flex justify-content-center mx-2 mb-3 F-size16">
                  We appreciate it. Your response will make our customer service better.
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
