import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Storage, Auth } from 'aws-amplify';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { useToast } from 'context/ToastContext';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { getFileClients, presignedURLGet, sendESigantureRequest, sendESigntureRequestZoho } from 'services/fileManager/fileManagerServices';
import { toTitleCase } from 'utils/utils';
import { toastConstant } from 'constants/toastmessage';
import CustomHeader from './../CustomHeader';
import { useHistory, useLocation } from 'react-router-dom';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputSwitch } from 'primereact/inputswitch';
import { uuidv4 } from 'common/uuidGenerator';

function SignatureModal() {
  const { modals, selectedItem, caseDetails, isCaseFileManager, isLeadFileManager, handleGetFiles, pageLimit, fileManagerFilters } =
    useFileManagerContext();

  const eSignReqScheme = Yup.object().shape({
    file_name: Yup.string().required('File name is required'),
    notes: Yup.string(),
    is_sequential: Yup.boolean(),
    assignee: Yup.array()
      .of(
        Yup.object().shape({
          assignee: Yup.string().required('Assignee is required'),
          notes: Yup.string(),
        })
      )
      .required('Assignee is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(eSignReqScheme),
    defaultValues: {
      file_name: '',
      notes: '',
      is_sequential: false,
      assignee: [{ assignee: '', notes: '', id: uuidv4() }],
    },
  });

  const {
    fields,
    append,
    remove,
    watch: arrayWatch,
  } = useFieldArray({
    control,
    name: 'assignee',
  });

  const { showSignatureModal, setShowSignatureModal } = modals;

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  const [instance, setInstance] = useState(null);
  const [PSPDFKit, setPSPDFKit] = useState(false);
  const [assignee, setAssignee] = useState('');
  const [allClientOptions, setAllClientOptions] = useState([]);
  const [filteredClientOptions, setFilteredClientOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [notes, setNotes] = useState('');
  const [file_name, setFileName] = useState(selectedItem?.file_name.split());
  const [draggingItem, setDraggingItem] = useState(null);

  let AssigneeList = watch('assignee');

  useEffect(() => {
    if (selectedItem?.file_name) {
      let name = selectedItem?.file_name.split('.');
      name.pop();
      name = name.join('.');
      setFileName(name);
      setValue('file_name', name);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem?.case_id) {
      getFileClients({ case_id: selectedItem.case_id })
        .then((response) => {
          if (response && response.data && response.data.length) {
            let option = [];
            response.data
              .filter((val) => val.email)
              .forEach((element) => {
                let obj = {};
                let fullName = '';
                fullName = `${element.first_name ? toTitleCase(element.first_name) : ''} ${
                  element.middle_name ? toTitleCase(element.middle_name) : ''
                } ${element.last_name ? toTitleCase(element.last_name) : ''}`;
                obj.value = element.client_cognito_username;
                if (element.is_create_client_portal === false && element.status !== 'ACTIVE') {
                  obj.label = fullName + ' (Inactive and Disabled Lawft Access)';
                } else if (element.is_create_client_portal === false) {
                  obj.label = fullName + ' (Disabled Lawft Access)';
                } else if (element.status !== 'ACTIVE') {
                  obj.label = fullName + ' (Inactive)';
                } else if (element.is_confirmed && element.is_confirmed === true) {
                  obj.label = fullName;
                } else if (!element.is_confirmed || element.is_confirmed === false) {
                  obj.label = fullName + ' (Not Confirmed)';
                } else {
                  obj.label = fullName;
                }
                obj.value = element.contact_id;

                if (element.status !== 'ACTIVE') {
                  obj.disabled = true;
                } else {
                  obj.disabled = false;
                }
                option.push(obj);
              });
            setAllClientOptions(option);
            setFilteredClientOptions(option);
          } else {
            setAllClientOptions([]);
            setFilteredClientOptions([]);
          }
        })
        .catch((error) => {
          setAllClientOptions([]);
          setFilteredClientOptions([]);
        });
    }
  }, [selectedItem]);

  useEffect(() => {
    (async function () {
      let url = await fetchData();
      setUrl(url);
    })();
  }, [selectedItem]);

  const fetchData = async () => {
    let file_path = selectedItem?.file_path;
    let version_id = selectedItem?.version_id;
    let response = await presignedURLGet(file_path, version_id);
    if (response.data) {
      return response?.data?.file_url;
    }
    return '';
  };

  const applyFields = async () => {
    await uploadForSigning();
  };

  const uploadForSigning = async () => {
    setIsLoading(true);
    const arr = await instance?.exportPDF();
    const blob = new Blob([arr], { type: 'application/pdf' });
    await updateFile([blob]);

    let sendData = {};
    sendData.file_name = file_name;
    sendData.notes = notes;
    sendData.client_cognito_username = [assignee];

    let obj = {
      case_id: selectedItem.case_id,
      file_pk: selectedItem?.pk,
      file_sk: selectedItem?.sk,
      data: sendData,
      is_lead: isLeadFileManager,
    };

    //requesting foe signature
    sendESigantureRequest(obj)
      .then(() => {
        setIsLoading(false);
        handleGetFiles(pageLimit);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.E_SIGNATURE_SEND_SUCCESS);
      })
      .catch((error) => {
        let msg = toastConstant.message.E_SIGNATURE_SEND_FAILED;
        if (error.response.data === 'Invalid Case') msg = 'File is not linked with a case';
        setIsLoading(false);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, msg);
      });
  };

  const updateFile = async (files) => {
    if (files && files.length > 0) {
      // let userId;
      SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
      await Auth.currentAuthenticatedUser();

      for (let i = 0; i < files.length; i++) {
        let pathFile = selectedItem && selectedItem.file_path && selectedItem.file_path.split('public/')[1];
        let response = await Storage.put(pathFile, files[i], {
          // tagging: `tenant=${tenantId}`,
          contentType: files[i].type,
          progressCallback: (progress) => {
            const { loaded, total } = progress;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
              //setUploadPercentage(percent);
            }
          },
          useAccelerateEndpoint: true,
        });
        if (response.key) {
          // console.log(response.key);
        }
      }
    }
  };

  const customHeaderTemplate = (data) => {
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <i className="icon-window-filled pointer F-size14" onClick={data?.onHide}></i>
            {/* <i className="icon-window-filled pointer F-size14 icon-minimize"></i> */}
          </div>
          <span></span>
        </div>
      </div>
    );
  };

  const handleDragDate = useCallback((ev) => ev.dataTransfer.setData('text/plain', 'date'), []);

  const handleDragSignature = useCallback((ev) => ev.dataTransfer.setData('text/plain', 'signature'), []);

  const handleZohoEsignReq = () => {
    let sendData = {};
    sendData.file_name = file_name;
    sendData.notes = notes;
    sendData.client_cognito_username = [assignee];

    let obj = {
      case_id: selectedItem.case_id,
      file_pk: selectedItem?.pk,
      file_sk: selectedItem?.sk,
      data: sendData,
      is_lead: isLeadFileManager,
    };

    // history.push({
    //   pathname: '/signature-request',
    //   state: obj,
    //   search: `?to=${location?.pathname}`

    // });

    // sendESigntureRequestZoho(obj)
    // .then(() => {
    //   setIsLoading(false);
    //   // handleGetFiles(pageLimit);
    //   addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.E_SIGNATURE_SEND_SUCCESS);
    // })
    // .catch((error) => {
    //   let msg = toastConstant.message.E_SIGNATURE_SEND_FAILED;
    //   if (error.response.data === 'Invalid Case') msg = 'File is not linked with a case';
    //   setIsLoading(false);
    //   addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, msg);
    // });
  };
  const onSubmit = (data) => {
    let sendData = {};
    sendData.file_name = data?.file_name;
    sendData.notes = data?.notes;
    sendData.client_cognito_username = data?.assignee;
    sendData.is_sequential = data?.is_sequential;

    let obj = {
      case_id: selectedItem.case_id,
      file_pk: selectedItem?.pk,
      file_sk: selectedItem?.sk,
      data: sendData,
      is_lead: isLeadFileManager,
      case_name: fileManagerFilters?.case?.case_name,
      display_name: fileManagerFilters?.case?.label,
    };

    history.push({
      pathname: '/signature-request',
      state: obj,
      search: `?to=${location?.pathname}`,
    });

    console.log('data', data);
  };

  const footer = (
    <div>
      <Button label="Cancel" onClick={() => setShowSignatureModal(false)} className="p-button p-button-secondary outline mx-2" />
      <Button
        label="Step 2 : Add Signature"
        // onClick={handleZohoEsignReq}
        onClick={() => handleSubmit(onSubmit)()}
        className="p-button p-button-secondary"
      />
    </div>
  );

  const addRow = () => {
    const id = uuidv4();
    append({ assignee: '', notes: '', id });
  };

  const handleDragStart = (e, index) => {
    console.log(AssigneeList, index, 'LLLLLLL');
    let selected = AssigneeList[index];
    // console.log(item, "ITEMMMMM")
    setDraggingItem(selected);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    // console.log(e,targetItem, "KKKK")
    if (!draggingItem) return;
    let _assigneeList = getValues('assignee');
    // console.log(_assigneeList, targetItem, draggingItem, 'LLLLL');
    const currentIndex = _assigneeList.findIndex((e) => e.assignee == draggingItem.assignee);
    const targetIndex = _assigneeList.findIndex((e) => e.assignee == targetItem.assignee);
    // console.log(_assigneeList, targetItem, draggingItem, currentIndex, targetIndex, 'LLLLL');

    if (currentIndex !== -1 && targetIndex !== -1) {
      _assigneeList.splice(currentIndex, 1);
      _assigneeList.splice(targetIndex, 0, draggingItem);
      // console.log(_assigneeList, 'UPDATEEEE');

      setValue('assignee', _assigneeList);
    }
  };

  // const _watch = watch({nested:true});

  //  useEffect(()=>{
  //   console.log(allClientOptions, "KKKKK")
  //   let allClients = [...allClientOptions];
  //   let selected = [...getValues("assignee")]
  //   let selectedClients = selected.map(item=> item.assignee)
  //   console.log(selected, "FFFFF")

  //   let filteredClients = allClients.filter(item=> !selectedClients.includes(item.value))
  //   // return filteredClients
  //   // setFilteredClientOptions(filteredClients)
  //   // console.log(allClients,selectedClients, "JJJ" )

  // },[_watch])
  // console.log(filtered, "FFFFF")

  let is_sequential = watch('is_sequential');
  return (
    <Dialog
      className="common-popup-style"
      header={<CustomHeader onHide={() => setShowSignatureModal(false)} title="E-sign Request" />}
      footer={footer}
      visible={showSignatureModal}
      style={{ width: '50vw' }}
      modal
      onHide={() => setShowSignatureModal(false)}
      closable={false}

      // visible={showSignatureModal}
      // modal
      // resizable={false}
      // draggable={false}
      // className="common-popup-style"
      // header={customHeaderTemplate}
      // onHide={() => setShowSignatureModal(false)}
      // closable={false}
    >
      {console.log(errors, 'EEEEEE')}
      <div className="w-100 h-100">
        <div className="d-flex flex-wrap h-100">
          <div className="pe-3 my-3 col-12 d-flex flex-column">
            <div className="header fw-bolder mb-2">File Name</div>
            <Controller
              name="file_name"
              control={control}
              // rules={{ required: 'File name is required' }}
              render={({ field }) => (
                <TextSnippetWrapper
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  type="text"
                  rows={4}
                  cols={30}
                  autoResize
                  className="w-100 "
                  placeholder="File name"
                  // {...field}
                />
              )}
            />
            {errors.file_name && <small className="p-error">{errors.file_name?.message}</small>}
            <br />
            <div className="header fw-bolder mb-2">Any notes you want to send with the e-sign request ?</div>
            <Controller
              name="notes"
              control={control}
              // rules={{}}
              render={({ field }) => (
                <TextSnippetWrapper
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  type="textarea"
                  rows={4}
                  cols={30}
                  autoResize
                  className="border shadow-small w-100 text-message-input"
                  placeholder="Notes"
                  // {...field}
                />
              )}
            />
            {errors.notes && <small className="p-error">{errors.notes?.message}</small>}

            <br />
            {fields?.length > 1 && (
              <div className="d-flex align-items-center py-2 py-lg-0 pe-4">
                <div className="header fw-bolder me-1">Is sequential</div>
                <Tooltip target=".custom-target-icon" />

                <i
                  className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge  me-3 pointer"
                  data-pr-tooltip="When this option is turned on, signers will be required to sign the document one after the other in a specific order. If disabled, all signers can sign the document simultaneously without waiting for others to complete their part."
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                ></i>

                <Controller
                  name="is_sequential"
                  control={control}
                  render={({ field }) => <InputSwitch className="input-swich swich-w" checked={field?.value} onChange={field.onChange} />}
                />
              </div>
            )}
          </div>

          <br />

          <div className="w-100 rounded-1 py-2 d-flex align-items-center justify-content-between">
            <div className="header fw-bolder mb-2">Recipients</div>
            <Button label="Add" onClick={() => addRow()} className="p-button p-button-secondary" />
          </div>
          {fields.map((item, index) => {
            return (
              <div
                onDragStart={(e) => handleDragStart(e, index)}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, item)}
                draggable
                key={item.id}
                className="w-100 rounded-1 py-2 d-flex align-items-center justify-content-between"
              >
                {fields.length > 1 && is_sequential && (
                  <div className="">
                    <i className="pi pi-align-justify cursor-pointer" />
                  </div>
                )}

                <div className="col-5 h-100">
                  <Controller
                    name={`assignee[${index}].assignee`}
                    // name={"assignee"}
                    control={control}
                    // defaultValue=""
                    // rules={{ required: 'Select a contact' }}
                    render={({ field }) => (
                      <>
                        <Dropdown
                          // invalid
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={filteredClientOptions}
                          optionLabel="label"
                          placeholder="Select Contact"
                          className="w-100"
                        />
                      </>
                    )}
                  />
                  <div className="">
                    {errors.assignee && errors.assignee[index]?.assignee && (
                      <small className="p-error ms-2">{errors.assignee[index]?.assignee?.message}</small>
                    )}
                  </div>
                </div>
                {fields?.length > 1 && (
                  <div className="col-5 h-100">
                    <Controller
                      name={`assignee[${index}].notes`}
                      control={control}
                      // rules={{}}
                      render={({ field }) => (
                        <TextSnippetWrapper
                          value={field.value}
                          onChange={field.onChange}
                          type="text"
                          // rows={1}
                          // cols={30}
                          autoResize
                          className="border shadow-small w-100 "
                          placeholder="Private Notes"
                          // {...field}
                        />
                      )}
                    />
                    <div className="">
                      {errors.assignee && errors.assignee[index]?.notes && (
                        <small className="p-error">{errors.assignee[index]?.notes?.message}</small>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-1 d-flex justify-content-end h-100">
                  {fields.length != 1 && (
                    <Button onClick={() => remove(index)} icon="pi pi-trash" rounded text severity="danger" aria-label="Cancel" />
                  )}
                </div>

                {/* <div className="header fw-bolder mb-2">Private Notes</div> */}
              </div>
            );
          })}

          {/* <div className="header fw-bold mb-2">{selectedItem?.file_name}</div> */}
        </div>
      </div>
    </Dialog>
  );
}

export default SignatureModal;
