import React, { useEffect, useRef } from 'react';

import { Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';

import LabelName from 'components/UI/LabelName/LabelName';

function EventNotLinkedToCase({ control, resetField, selectedcase, watch, setValue, editEventModal }) {
  const previousCaseValue = useRef(null);
  const notLinkedToCase = watch('not_linked_to_case');

  useEffect(() => {
    if (notLinkedToCase) {
      previousCaseValue.current = selectedcase;
      resetField('case');
    } else {
      if (previousCaseValue.current) {
        setValue('case', previousCaseValue.current);
      }
    }
  }, [notLinkedToCase]);
  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12"></div>
      <div className="col-md-8 col-12">
        <Controller
          name="not_linked_to_case"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={field.name}
              value={field.value}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              disabled={editEventModal}
            />
          )}
        />
        <LabelName required={false} htmlFor="not_linked_to_case" className="ms-2">
          Not Linked To Case/Lead
        </LabelName>
      </div>
    </div>
  );
}

export default EventNotLinkedToCase;
