import moment from 'moment';
import { convertToUTC } from './timezone';

export const isCurrentDatePastExpiry = (expiryDate, timezone) => {
  if (expiryDate) {
    const currentDateUTC = convertToUTC(timezone);
    return moment.utc(currentDateUTC).isAfter(expiryDate);
  }
  if (expiryDate === undefined) {
    return undefined;
  }
};
