import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import { ModalHeader } from 'shared/ModalHeader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getSharedLinkStats } from 'services/fileManager/fileManagerServices';
import moment from 'moment';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { convertToSpecificTimeZoneFormat, convertToUTC } from 'utils/utility_functions/timezone';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { Tooltip } from 'primereact/tooltip';

export const FileSharingDetailsModal = ({ show, onHide, selectedFile, setSelectedFile }) => {
  const userContext = useUserDetailsContext();

  const [selectedLog, setSelectedLog] = useState([]);
  const [selectedLinkDetails, setSelectedLinkDetails] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);

  const { addToast } = useToast();

  const handleEyeClick = (rowData) => {
    setButtonClicked(true);
    getSharedLinkStats({
      lawft_link_id: rowData?.lawft_link_id,
    })
      .then((response) => {
        setSelectedLog(response?.data);
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LOAD_ACTIVITY_LOGS_FAILED);
      });
  };

  const handleCopyToClipboard = (text) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CLIPBOARD_COPY_FAIL);
        });
    } else {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.message.CLIPBOARD_COPY_FAIL);
    }
  };

  const actionBody = (rowData) => {
    let isExpired = false;
    const currentDateUTC = moment(convertToUTC(userContext?.userDetails?.timezone));
    const expiryUTC = moment.utc(rowData?.link_expiry);

    const currentDateString = currentDateUTC.toISOString();
    const expiryDateString = expiryUTC.toISOString();

    if (expiryDateString <= currentDateString) {
      isExpired = true;
    }

    return (
      <>
        <i
          id={`additional-information-tooltip-${rowData?.lawft_link_id}`}
          className="fas fa-info-circle pointer text-primary mx-2 mt-1"
          onClick={() => handleEyeClick(rowData)}
        ></i>
        <i
          id={`copy-icon-tooltip-${rowData?.lawft_link_id}`}
          className={`icon-notes custom-tooltip-report mx-2 mt-1 pointer ${isExpired ? 'text-muted' : 'text-primary-main'}`}
          onClick={() => {
            if (!isExpired) {
              handleCopyToClipboard(rowData?.lawft_link_endpoint || '');
            }
          }}
          style={{
            pointerEvents: isExpired ? 'none' : 'auto',
            cursor: isExpired ? 'not-allowed' : 'pointer',
          }}
        ></i>
        <Tooltip target={`#additional-information-tooltip-${rowData?.lawft_link_id}`} position="top">
          Additional Information
        </Tooltip>
        <Tooltip target={`#copy-icon-tooltip-${rowData?.lawft_link_id}`} position="top">
          Copy Link
        </Tooltip>
      </>
    );
  };

  const getExpiryStatus = (expiryDate) => {
    const currentDateUTC = moment(convertToUTC(userContext?.userDetails?.timezone));
    const expiryUTC = moment.utc(expiryDate);

    const currentDateString = currentDateUTC.toISOString();
    const expiryDateString = expiryUTC.toISOString();

    if (expiryDateString <= currentDateString) {
      return <span className="text-expire">Expired</span>;
    }

    const duration = moment.duration(expiryUTC.diff(currentDateUTC));
    const minutes = Math.ceil(duration.asMinutes());
    const hours = Math.ceil(duration.asHours());

    if (minutes <= 0) {
      return <span className="text-expire">Expires</span>;
    } else if (minutes <= 1440 && hours <= 1) {
      return (
        <span className="text-expire">
          Expires in {minutes} {minutes === 1 ? 'minute' : 'minutes'}
        </span>
      );
    } else if (!minutes <= 1440 && hours >= 1 && hours <= 24) {
      return (
        <span className="text-expire">
          Expires in {hours} {hours === 1 ? 'hour' : 'hours'}
        </span>
      );
    } else {
      return (
        <>
          <span className="F-size12 black-500">Expires on</span>
          <br />
          <span>{convertToSpecificTimeZoneFormat(expiryUTC, userContext?.userDetails?.timezone)}</span>
        </>
      );
    }
  };

  useEffect(() => {
    if (selectedFile && selectedFile?.lawft_link_expires_on) {
      getSharedLinkStats({
        file_system_sk: selectedFile?.sk,
      })
        .then((response) => {
          setSelectedLinkDetails(response?.data?.['lawft_links'] || []);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedFile]);

  return (
    <Dialog
      header={ModalHeader}
      closable={false}
      visible={show}
      modal
      style={{ width: '80vw' }}
      onHide={() => {
        setSelectedLog(null); // Reset selected log on close
        setButtonClicked(false);
        setSelectedLinkDetails(null);
        setSelectedFile(null);
        onHide();
      }}
      resizable={false}
      className="create-msg-popup"
    >
      <div className="py-4">
        <div className="modal-heading F-size16 text-bold mb-2">{selectedFile?.display_name}</div>
        <div className="d-flex">
          <p>
            Total Views <span className="text-bold F-size16 ms-2">{selectedFile?.view_count || 0}</span>
          </p>
          <p className="ms-4">
            Total Downloads <span className="text-bold F-size16 ms-2">{selectedFile?.download_count || 0}</span>
          </p>
        </div>

        <div className="d-flex align-items-start" style={{ gap: '1rem' }}>
          {/* Generated Links Section using DataTable */}
          <div
            className="flex-grow-1 file-sharedlist-table"
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
              width: selectedLog !== null ? '60%' : '100%', // Adjust width when eye button is clicked
              transition: 'width 0.3s ease', // Smooth transition for resizing
            }}
          >
            <h5 className="caption-bold mb-2">Generated Links</h5>

            <div className="col-12 p-0 datatable-responsive">
              <DataTable value={selectedLinkDetails} className="p-datatable-responsive">
                <Column
                  header="Lawft Link Nickname"
                  body={(rowData) => {
                    const nickname = rowData?.nickname || '-';
                    return <>{nickname}</>;
                  }}
                  field="nickname"
                />
                <Column header="Status" body={(rowData) => <span>{getExpiryStatus(rowData?.link_expiry)}</span>} />
                <Column
                  header="Activity"
                  body={(rowData) => {
                    return (
                      <>
                        <div>
                          <span className="F-size12 black-500 me-2">Views:</span>
                          {rowData?.view_count || 0}
                        </div>
                        <div>
                          <span className="F-size12 black-500 me-2">Downloads:</span>
                          {rowData?.download_count || 0}
                        </div>
                      </>
                    );
                  }}
                />
                <Column header="Actions" body={actionBody} />
              </DataTable>
            </div>
          </div>

          {/* File Activity Logs Section (Conditional) */}
          {selectedLog !== null && buttonClicked && (
            <div
              className="p-4 file-sharedlist-table"
              style={{
                width: '35%', // Take up more space when eye button is clicked
                maxHeight: '300px',
                overflowY: 'auto',
                transition: 'width 0.3s ease', // Smooth transition for resizing
              }}
            >
              <h5 className="caption-bold mb-2">File Activity Logs</h5>

              <DataTable
                value={
                  selectedLog && (selectedLog?.file_view?.length > 0 || selectedLog?.file_download?.length > 0)
                    ? selectedLog.file_view.map((_, index) => ({
                        file_view: selectedLog?.file_view?.[index], // Get each file_view item
                        file_download: selectedLog?.file_download?.[index], // Get each file_download item
                      }))
                    : []
                }
              >
                <Column
                  header={`Views (${selectedLog?.file_view ? selectedLog?.file_view?.length : 0})`}
                  body={(rowData) => {
                    return rowData?.file_view
                      ? convertToSpecificTimeZoneFormat(rowData?.file_view, userContext?.userDetails?.timezone)
                      : '-';
                  }}
                />
                <Column
                  header={`Downloads (${selectedLog?.file_download ? selectedLog?.file_download?.length : 0})`}
                  body={(rowData) => {
                    return rowData?.file_download
                      ? convertToSpecificTimeZoneFormat(rowData?.file_download, userContext?.userDetails?.timezone)
                      : '-';
                  }}
                />
              </DataTable>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
