import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const createUser = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.user.create}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.POST, url, data);
};

export const listUsers = (
  status,
  limit = 10,
  pagination_token = '',
  pagination_direction = 'forward',
  keyWord = '',
  is_include_contacts = false,
  is_detailed = false,
  is_confirmed_user = false,
  is_include_groups = false,
  active_case_count = false,
  active_groups = true
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.user.list}?tenant_id=${tenantId}&status=${status}&keyword=${keyWord}&limit=${limit}&tenant_sk=${tenantSk}&is_include_contacts=${is_include_contacts}&is_detailed=${is_detailed}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&is_confirmed_user=${is_confirmed_user}&is_include_groups=${is_include_groups}&active_case_count=${active_case_count}&active_groups=${active_groups}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const getUserList = (
  status,
  limit = 10,
  pagination_token = '',
  pagination_direction = 'forward',
  keyWord = '',
  is_include_contacts = false,
  is_detailed = false,
  active_case_count = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.user.list}?tenant_id=${tenantId}&status=${status}&keyword=${keyWord}&limit=${limit}&tenant_sk=${tenantSk}&is_include_contacts=${is_include_contacts}&is_detailed=${is_detailed}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&active_case_count=${active_case_count}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

//TODO:Multi-tenancy
export const getUserDetails = (sk, pk, firm_user_id = '', client_cognito_username = '', active_case_count = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  sk = sk ? sk.replaceAll('#', '%23') : '';
  pk = pk ? pk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints?.user?.details}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}&firm_user_id=${firm_user_id}&client_cognito_username=${client_cognito_username}&active_case_count=${active_case_count}`;
  return performRequest(apiEndPoints?.methodType?.GET, url);
};

export const updateUserDetails = (data = {}, queryParams = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const { isRateUpdated = false } = queryParams;

  let url = `${apiEndPoints.user.update}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${
    isRateUpdated ? `&is_rate_updated=${isRateUpdated}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.PUT, url, data);
};

export const updateTeamMember = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.updateTeamMember}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data
  );
};

export const changeTenant = (userSk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.changeTenant}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&user_sk=${userSk}`,
    {}
  );
};

export const archiveUser = (pk, sk, action, clientCognitoUserName) => {
  const [tenantId] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.archiveUser}/${clientCognitoUserName}?tenant_id=${tenantId}&pk=${pk}&sk=${sk}&action=${action}`
  );
};

export const updateUserPermissions = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateUserPermission}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const setUserKpiTrackig = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.user.setKpiTrackig}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};
