export const toastConstant = {
  api: {
    SUCCESS: 'Success',
    FAILED: 'Failed',
    SOMETHING_WRONG: 'somethingWrong',
    RESPONSE_SUCCESS: 'responseSuccess',
    RESPONSE_FAILED: 'responseFailed',
    NAME_DUPLICATION_CODE: 409,
  },
  toasterType: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
    WARN: 'warn',
  },

  header: {},

  message: {
    EMAIL_SENT: 'An email is sent to your registered email id',
    MESSAGE_ERROR: 'Sorry. An error occurred.',
    LOGIN_SUCCESS: 'User logged in successfully',
    PASSWORD_RESET: 'Your password is reset.',
    PROFILE_UPDATE_SUCCESS: 'Your profile details are updated.',
    PROFILE_UPDATE_FAILED: 'Failed to update your profile details.',
    PASSWORD_UPDATE_SUCCESS: 'Your password is updated.',
    PASSWORD_RESET_REQ_SUCCESS: 'Password reset request sent.',
    PASSWORD_RESET_REQ_FAILED: 'Failed to reset your password. Please try again.',
    PASSWORD_UPDATE_FAILED: 'Failed to update your password.',
    ACCOUNT_VERIFIED_SUCCESS: 'Your account has been verified.',
    ACCOUNT_VERIFIED_FAILED: 'Failed to verify your account',
    CONTACT_CREATE_SUCCESS: 'Contact created successfully',
    CONTACT_CREATE_FAILED: 'Failed to create contact',
    CONTACT_UPDATE_SUCCESS: 'Contact updated successfully',
    CONTACT_UPDATE_FAILED: 'Failed to update contact',
    CASE_CREATED: 'Case created successfully',
    CASE_UPDATED_CREATED: 'Case details updated successfully',
    CASE_COMPANION_UPDATED: 'Case companion updated successfully',
    CASE_COMPANION_DELETE: 'Case companion deleted successfully',
    CASE_COMPANION_UPDATED_FAILED: 'Failed to update case companion',
    CASE_COMPANION_DELETE_FAILED: 'Failed to delete case companion',
    CASE_UPDATED_FAILED: 'Failed to update case details',
    CUSTOM_FIELD_CREATED: 'Custom Field created successfully',
    UPDATE_CUSTOM_FIELD: 'Custom Field updated successfully',
    DELTE_CUSTOM_FIELD: 'Custom Field deleted successfully',
    CUSTOM_FIELD_CREATED_FAILED: 'Failed to create Custom Field',
    CUSTOM_FIELD_UPDATED_FAILED: 'Failed to update Custom Field',
    CUSTOM_FIELD_DELETED_FAILED: 'Failed to delete Custom Field',
    USER_CREATE_SUCCESS: 'User created successfully',
    USER_CREATE_FAILURE: 'Failed to create user',
    USER_UPDATE_SUCCESS: 'User Details updated successfully',
    USER_UPDATE_FAILURE: 'Failed to update user details',
    CASE_MATTER_CREATED: 'Case matter created successfully',
    CASE_MATTER_DELETED: 'Case matter deleted successfully',
    CASE_MATTER_UPDATED: 'Case matter updated successfully',
    CASE_MATTER_CREATED_FAILED: 'Failed to create Case matter.',
    CASE_MATTER_DELETED_FAILED: 'Failed to delete Case matter.',
    CASE_MATTER_UPDATED_FAILED: 'Failed to update Case matter.',
    CASE_NUMBER_EXIST: 'Case Number already exist',
    PRACTICE_AREA_CREATED_SUCCESS: 'Practice Area created successfully',
    PRACTICE_AREA_DELETED_SUCCESS: 'Practice Area deleted successfully',
    PRACTICE_AREA_UPDATED_SUCCESS: 'Practice area updated successfully',
    PRACTICE_AREA_CREATED_FAILED: 'Failed to create Practice Area.',
    PRACTICE_AREA_DELETED_FAILED: 'Failed to delete Practice Area.',
    PRACTICE_AREA_UPDATED_FAILED: 'Failed to update Practice Area.',
    CASE_MATTER_NAME_VALIDATION: 'Name is required',
    CASE_STAGE_CREATED: 'Case stage created',
    CASE_STAGE_UPDATED: 'Case stage updated',
    CASE_STAGE_DELETED: 'Case stage deleted',
    CASE_STAGE_CREATED_FAILED: 'Failed to create Case stage.',
    EVENT_CREATED_SUCCESS: 'Event created successfully',
    EVENT_CREATE_FAILURE: 'Failed to create event',
    EVENT_DELETE_SUCCESS: 'Event deleted successfully',
    EVENT_DELETE_FAILURE: 'Failed to delete event',
    EVENT_UPDATE_SUCCESS: 'Event successfully updated',
    EVENT_UPDATE_FAILURE: 'Failed to update Event',
    CASE_COURT_CREATED: 'Case court created successfully',
    CASE_COURT_DELETED: 'Case court deleted successfully',
    CASE_COURT_NAME_VALIDATION: 'Name is required',

    //Expert
    EXPERT_NAME_VALIDATION: 'Expert name is required',

    //Venue
    VENUE_CREATED: 'Venue created successfully',
    VENUE_DELETED: ' Venue deleted successfully',
    VENUE_NAME_VALIDATION: 'Name is required',

    VENUE_CREATE_SUCCESS: 'Venue created successfully',
    VENUE_CREATE_FAILED: 'Failed to create Venue',
    VENUE_UPDATE_SUCCESS: 'Venue updated successfully',
    VENUE_UPDATE_FAILED: 'Failed to update Venue',

    REFERRAL_CREATED: 'Referral created successfully',
    REFERRAL_NAME_VALIDATION: 'Name is required',

    //Review Request Url
    REVIEW_REQUEST_URL_CREATE_SUCCESS: 'Review request url created successfully',
    REVIEW_REQUEST_URL_CREATE_FAILED: 'Failed to create review request url',
    REVIEW_REQUEST_URL_UPDATE_SUCCESS: 'Review request url updated successfully',
    REVIEW_REQUEST_URL_UPDATE_FAILED: 'Failed to update review request url',
    REVIEW_REQUEST_URL_DELETE_SUCCESS: 'Review request url deleted successfully',
    REVIEW_REQUEST_URL_DELETE_FAILED: 'Failed to delete review request url',
    REQUEST_REVIEW_MESSAGE_UPDATE_SUCCESS: 'Request review message updated successfully',
    REQUEST_REVIEW_MESSAGE_UPDATE_FAILED: 'Failed to update request review message',
    REVIEW_REQUEST_SENT_SUCCESS: 'Review request sent successfully',
    REVIEW_REQUEST_SENT_FAILED: 'Failed to sent review request',

    NOTE_UPDATED_CREATED: 'Case note details updated successfully',
    NOTE_UPDATED_FAILED: 'Failed to update case note details',
    LEAD_STAGE_CREATED: 'Lead stage created',
    LEAD_STAGE_UPDATED: 'Lead stage updated',
    LEAD_STAGE_DELETED: 'Lead stage deleted',
    OFFICE_LOCATION_CREATED: 'Office location created',
    OFFICE_LOCATION_DELETED: 'Office location deleted',
    OFFICE_LOCATION_UPDATED: 'Office location updated',
    EVENT_TYPE_CREATE_SUCCESS: 'Event type created successfully',
    EVENT_TYPE_CREATE_FAILURE: 'Failed to create event type',
    EVENT_TYPE_UPDATED_SUCCESS: 'Event type updated successfully',
    EVENT_TYPE_UPDATE_FAILURE: 'Failed to updated event type',
    EVENT_TYPE_DELETE_SUCCESS: 'Event type deleted successfully',
    EVENT_TYPE_DELETE_FAILURE: 'Failed to delete event',
    FORGOT_PASSWORD_UNREGISTERED_CODE: 'UserNotFoundException',
    FORGOT_PASSWORD_UNREGISTERED: 'This username is not registered with Lawft.',
    TASK_CREATED_SUCCESSFULLY: 'Task created successfully',
    TASK_DELETED_SUCCESSFULLY: 'Task deleted successfully',
    TASK_UPDATED_SUCCESSFULLY: 'Task updated successfully',
    TASK_UPDATED_FAILED: 'Failed to update task',
    STAGE_UPDATED_SUCCESSFULLY: 'Stage updated successfully',
    STAGE_UPDATED_FAILED: 'Failed to update Stage',
    STAGE_DELETE_FAILED: 'Failed to delete Stage',
    STAGE_DELETE_SUCCESSFULLY: 'Stage deleted successfully',
    STAGE_CREATED_SUCCESSFULLY: 'Stage created successfully',

    LOCATION_CREATE_SUCCESS: 'Location created successfully',
    LOCATION_CREATE_FAILURE: 'Failed to add location',
    CALENDAR_SETTINGS_UPDATE_SUCCESS: 'Settings updated successfully',
    CALENDAR_SETTINGS_UPDATE_FAILURE: 'Failed to update settings',
    MESSAGE_SEND_FAILURE: 'Failed to send message',
    FOLDER_CREATED: 'Success Creating Folder',
    FOLDER_CREATE_FAILED: 'Failed to Create Folder',
    FILES_UPLOADED: 'Files Uploaded Successfully',
    NETWORK_ERROR: 'Network Error',
    FILE_UPLOAD_FAILED: 'Failed to Upload File',
    FILE_DELETE_FAILED: 'Failed to Delete File',
    FILE_DELETE_SUCCESS: 'File Deleted Successfully',
    FILE_RENAME_FAILED: 'Failed to Rename File',
    FILE_RENAME_SUCCESS: 'File Renamed Successfully',
    SELECT_TARGET_FOLDER_FIRST: 'Select Target Folder First',
    FILE_MOVED_SUCCESS: 'File Moved Successfully',
    FILE_MOVE_FAILED: 'Failed to Move File',
    FILE_MOVE_ERROR: 'Target Folder and Existing folder cannot be same',
    E_SIGNATURE_SEND_SUCCESS: 'File sent successfully',
    E_SIGNATURE_SEND_FAILED: 'Failed to send file',
    E_SIGNATURE_SIGN_PAGE_FAILED: 'Failed to open sign page',

    USER_GROUP_CREATE_SUCCESS: 'Group created successfully',
    USER_GROUP_CREATE_FAILURE: 'Failed to create user group',
    USER_GROUP_UPDATE_SUCCESS: 'Group updated successfully',
    USER_GROUP_UPDATE_FAILURE: 'Failed to update user group',
    USER_GROUP_DELETE_SUCCESS: 'Group deleted successfully',
    USER_GROUP_DELETE_FAILURE: 'Failed to delete user group',

    USER_ROLE_CREATE_SUCCESS: 'Role create successfully',
    USER_ROLE_CREATE_FAILURE: 'Failed to create user role',
    USER_ROLE_UPDATE_SUCCESS: 'Role updated successfully',
    USER_ROLE_UPDATE_FAILURE: 'Failed to update user role',
    USER_ROLE_DELETE_SUCCESS: 'Role deleted successfully',
    USER_ROLE_DELETE_FAILURE: 'Failed to delete user role',

    LEAD_CREATE_SUCCESS: 'Lead created successfully',
    LEAD_CREATE_FAILURE: 'Failed to create lead',
    LEAD_UPDATE_SUCCESS: 'Lead updated successfully',
    LEAD_UPDATE_FAILURE: 'Failed to update lead',
    LEAD_CLOSE_SUCCESS: 'Lead closed successfully',
    LEAD_CLOSE_FAILURE: 'Failed to close lead',
    LEAD_CONVERT_SUCCESS: 'Lead converted to case successfully',
    LEAD_CONVERT_FAILURE: 'Failed to convert lead to case',
    LEAD_DELETE_SUCCESS: 'Lead deleted successfully',
    LEAD_DELETE_FAILURE: 'Failed to delete lead',
    LEAD_OPEN_SUCCESS: 'Lead opened successfully',
    LEAD_OPEN_FAILURE: 'Failed to open lead',
    MESSAGE_SEND_SUCCESS: 'Message sent successfully',
    ACTION_RESTRICTED: 'Action restricted for this data',
    DATA_MOVED_SUCCESS: 'Data moved successfully',
    DATA_MOVED_FAILURE: 'Failed to move data',
    LEAD_DRAFT_CREATE_SUCCESS: 'Lead draft created successfully',
    LEAD_DRAFT_CREATE_FAILURE: 'Failed to create lead draft',
    LEAD_DRAFT_UPDATE_SUCCESS: 'Lead draft updated successfully',
    LEAD_DRAFT_UPDATE_FAILURE: 'Failed to update lead draft',
    LEAD_DISCARD_SUCCESS: 'Lead discarded successfully',
    LEAD_DISCARD_FAILURE: 'Failed to discard lead',

    UPDATE_RELATION_SUCCESS: 'Relation updated successfully',
    UPDATE_RELATION_FAILURE: 'Failed to update relation',
    DELETE_RELATION_SUCCESS: 'Relation deleted successfully',
    DELETE_RELATION_FAILURE: 'Failed to delete relation',
    CREATE_RELATION_SUCCESS: 'Relation created successfully',
    CREATE_RELATION_FAILURE: 'Failed to create relation',

    TEAM_MEMBER_DELETE_SUCCESS: 'Team member deleted successfully',
    TEAM_MEMBER_DELETE_FAILED: 'Failed to delete team member',
    CASE_DELETE_SUCCESS: 'Case deleted successfully',
    CASE_DELETE_FAILURE: 'Failed to delete case',
    CASE_OPEN: 'Case Re-opened successfully',
    CASE_CLOSE: 'Case Closed successfully',
    CASE_STAGE_UPDATED_FAILED: 'Failed to update case stage',

    ACTIVITY_TYPE_CREATE_SUCCESS: 'Activity type created successfully',
    ACTIVITY_TYPE_CREATE_FAILURE: 'Failed to create activity type',
    ACTIVITY_TYPE_UPDATE_SUCCESS: 'Activity type updated successfully',
    ACTIVITY_TYPE_UPDATE_FAILURE: 'Failed to update activity type',
    ACTIVITY_TYPE_DELETE_SUCCESS: 'Activity type deleted successfully',
    ACTIVITY_TYPE_DELETE_FAILURE: 'Failed to delete activity type',

    DELETE_TIME_ENTRY_SUCCESS: 'Successfully deleted time entry',
    DELETE_TIME_ENTRY_FAILED: 'Failed to delete time entry',
    CREATE_TIME_ENTRY_SUCCESS: 'Successfully created time entry',
    CREATE_TIME_ENTRY_FAILED: 'Failed to create time entry',
    UPDATE_TIME_ENTRY_SUCCESS: 'Successfully updated time entry',
    UPDATE_TIME_ENTRY_FAILED: 'Failed to update time entry',

    UPDATE_TEAM_MEMBER_SUCCESS: 'Team Member updated successfully',
    UPDATE_TEAM_MEMBER_FAILURE: 'Failed to update team member',

    EXPENSE_CREATE_SUCCESS: 'Expense created successfully',
    EXPENSE_CREATE_FAILED: 'Failed to create expense',
    EXPENSE_UPDATED_SUCCESS: 'Expense updated successfully',
    EXPENSE_UPDATE_FAILED: 'Failed to updated expense',
    EXPENSE_DELETE_SUCCESS: 'Expense deleted successfully',
    EXPENSE_DELETE_FAILURE: 'Failed to delete expense',
    SETTLEMENT_UPDATE_SUCCESS: 'Settlement updated successfully',

    MEDICAL_EXPENSE_CREATE_SUCCESS: 'Medical Expense created successfully',
    MEDICAL_EXPENSE_CREATE_FAILED: 'Failed to create Medical expense',
    MEDICAL_EXPENSE_UPDATED_SUCCESS: 'Medical Expense updated successfully',
    MEDICAL_EXPENSE_UPDATE_FAILED: 'Failed to updated Medical expense',
    MEDICAL_EXPENSE_DELETE_SUCCESS: 'Medical Expense deleted successfully',
    MEDICAL_EXPENSE_DELETE_FAILURE: 'Failed to delete Medical expense',

    ACCOUNT_CREATE_SUCCESS: 'Account created successfully',
    ACCOUNT_CREATE_FAILURE: 'Failed to create account',

    ACCOUNT_UPDATE_SUCCESS: 'Account updated successfully',
    ACCOUNT_UPDATE_FAILURE: 'Failed to update Account',

    ACCOUNT_DELETE_SUCCESS: 'Account deleted successfully',
    ACCOUNT_DELETE_FAILURE: 'Failed to delete account',

    EXPENSE_TYPE_CREATE_SUCCESS: 'Expense type created successfully',
    EXPENSE_TYPE_CREATE_FAILED: 'Failed to create expense type',

    EXPENSE_TYPE_UPDATE_SUCCESS: 'Expense type updated successfully',
    EXPENSE_TYPE_UPDATE_FAILED: 'Failed to update expense type',

    EXPENSE_TYPE_DELETE_SUCCESS: 'Expense type deleted successfully',
    EXPENSE_TYPE_DELETE_FAILURE: 'Failed to delete expense codes',

    TEAM_MEMBER_CASE_ADD_SUCCESS: 'Team member added successfully',
    TEAM_MEMBER_CASE_ADD_FAILED: 'Failed to add team member',
    RELATION_CASE_ADD_SUCCESS: 'Relationship added successfully',
    RELATION_CASE_ADD_FAILED: 'Failed to add relationship',

    CREATE_PAYMENT_REQUEST_SUCCESS: 'Payment request created successfully',
    CREATE_PAYMENT_REQUEST_FAILED: 'Failed to create payment request',

    UPDATE_PAYMENT_REQUEST_SUCCESS: 'Payment request updated successfully',
    UPDATE_PAYMENT_REQUEST_FAILED: 'Failed to update payment request',

    DELETE_PAYMENT_REQUEST_SUCCESS: 'Payment request deleted successfully',
    DELETE_PAYMENT_REQUEST_FAILED: 'Failed to delete payment request',

    CREATE_PAYMENT_RECORD_SUCCESS: 'Payment record created successfully',
    CREATE_PAYMENT_RECORD_FAILED: 'Failed to create payment record',

    UPDATE_PAYMENT_RECORD_SUCCESS: 'Payment record updated successfully',
    UPDATE_PAYMENT_RECORD_FAILED: 'Failed to update payment record',

    DELETE_PAYMENT_RECORD_SUCCESS: 'Payment record deleted successfully',
    DELETE_PAYMENT_RECORD_FAILED: 'Failed to delete payment record',

    NOTIFY_PAYMENT_REQUEST_SUCCESS: 'Successfully send notification mail',
    NOTIFY_PAYMENT_REQUEST_FAILED: 'Failed to send notification mail',

    REFUND_REQUEST_CREATE_SUCCESS: 'Refund request created successfully',
    REFUND_REQUEST_CREATE_FAILED: 'Failed to create refund request',

    SET_NEW_TENANT_SUCCESS: 'Changed firm admin successfully',
    SET_NEW_TENANT_FAILED: 'Failed to set firm admin',

    SET_DEACTIVATE_ACC_SUCCESS: 'Your firm account has been deactivated!',
    SET_DEACTIVATE_ACC_FAILED: 'Failed to deactivate firm account',

    FIRM_NAME_UPDATE_SCUCCESS: 'Firm name updated successfully',
    FIRM_NAME_UPDATE_FAILED: 'Failed to update firm name',

    WORKFLOW_CREATION_SUCCESS: 'Workflow has been created successfully',
    WORKFLOW_UPDATION_SUCCESS: 'Workflow has been updated successfully',
    WORKFLOW_DELETION_SUCCESS: 'Workflow has been deleted successfully',
    WORKFLOW_DELETION_FAILED: 'Failed to delete workflow',
    WORKFLOW_STATUS_UPDATE_SUCCESS: 'Workflow status updated successfully',
    WORKFLOW_STATUS_UPDATE_FAILED: 'Workflow status update failed',
    WORKFLOW_CREATION_FAILED: 'Failed to create workflow',
    WORKFLOW_UPDATION_FAILED: 'Failed to updated workflow',
    WORKFLOW_AUTOMATIN_RULE_VALIDATION_FAILED: 'This trigger-action cannot be added as it conflicts with another automation rule',

    TAGS_ADDED_SUCCESS: 'Tags added successfully',
    TAGS_ADDED_FAILED: 'Failed to save',
    TAGS_UPDATED_SUCCESS: 'Tags updated successfully',
    TAGS_UPDATED_FAILED: 'Failed to update tags',

    REMINDER_UPDATE_SUCCESS: 'Reminder updated successfully',
    REMINDER_UPDATE_FAILED: 'Failed to update reminder',

    REMINDER_CREATE_SUCCESS: 'Reminder created successfully',
    REMINDER_CREATE_FAILED: 'Failed to create reminder',

    REMINDER_DELETE_SUCCESS: 'Reminder delete successfully',
    REMINDER_DELETE_FAILED: 'Failed to delete reminder',

    INVOICE_CREATE_SUCCESS: 'Invoice created successfully',
    INVOICE_CREATE_FAILED: 'Failed to create Invoice',

    FILE_UPLOAD_WARNING: 'Please upload single file',

    DELETE_CONTACT_FAILED: 'Unable to delete contact',
    DELETE_CONTACT_SUCCESS: 'Contact deleted successfully',

    DELETE_INVOICE_SUCCESS: 'Invoice deleted successfully',
    DELETE_INVOICE_FAILED: 'Failed to delete invoice',

    INVOICE_UPDATE_SUCCESS: 'Invoice updated successfully',
    INVOICE_UPDATE_FAILED: 'Failed to updated Invoice',

    NUMBER_PURCHASED_SUCCESS: 'Successfully selected Number',
    NUMBER_PURCHASED_FAILED: 'Failed to purchase number',
    TEMPLATE_UPLOADED: 'Template(s) Uploaded Successfully',
    TEMPLATE_UPDATED: 'Template Updated Successfully',
    TEMPLATE_UPDATE_FAILED: 'FAILED to update template',
    TEMPLATE_UPLOAD_FAILED: 'Failed to Upload Template',
    TEMPLATE_DELETE_FAILED: 'Failed to Delete Template',
    TEMPLATE_DELETE_SUCCESS: 'Template Deleted Successfully',
    TEMPLATE_COPY_SUCCESS: 'Template Copied Successfully',
    TEMPLATE_COPY_FAILED: 'Failed to Copy Template',

    TEMPLATE_RENAME_FAILED: 'Failed to Rename Template',
    TEMPLATE_RENAME_SUCCESS: 'Template Renamed Successfully',
    DUPLICATE_CONTACT_EXITS: 'Duplicate contact exists change email or phone number',

    SETTINGS_SAVE_SUCCESS: 'Settings Saved Successfully',
    SETTINGS_SAVE_FAILURE: 'Failed to Save Settings',
    LEAD_CONTACT_SAVED: 'Saved contact data',
    LEAD_CONTACT_SAVE_FAILED: 'Failed to save contact data',
    LEAD_RELATION_DELETED: 'Lead relation deleted successfully',
    FAILED_TO_DELETE_RELATION: 'Failed to delete relation',
    FILES_SHARED: 'File(s) shared Successfully',
    FILE_SHARED_FAILED: 'Failed to Share File',

    SEND_INVOICE_SUCCESS: 'Invoice sent successfully',
    SEND_INVOICE_FAILED: 'Failed to send invoice',
    LEAD_CONTACT_FORM_VALIDATION: 'Please add or save contact,client details',
    PAYMENT_STRUCTURE_TAX_CREATED_SUCCESS: 'Tax value created successfully',
    PAYMENT_STRUCTURE_TAX_SUCCESS: 'Tax value updated successfully',
    PAYMENT_STRUCTURE_TAX_FAILED: 'Failed to update tax value',
    PAYMENT_STRUCTURE_DATA_SUCCESS: 'Payment structure data updated successfully',
    PAYMENT_STRUCTURE_DATA_FAILED: 'Payment structure data updated successfully',

    FAILED_REPORT: 'Failed to load Report',
    FAILED_GLANCE_TOTALS: 'Failed to load Totals',
    EXPORT_REPORT_FAILED: 'Failed to export Report',

    PAYMENT_REPORT_FAILED: 'Fee Type is required to run report',

    LEAD_STAGE_UPDATED_FAILED: 'Failed to update lead stage',
    SUCCESS_REPORT: 'Document saved to file manager',

    ROLE_CREATE_SUCCESS: 'Relation role created successfully',
    ROLE_CREATE_FAILED: 'Failed to create relation role',
    ROLE_UPDATE_SUCCESS: 'Relation role updated successfully',
    ROLE_UPDATE_FAILED: 'Failed to update relation role',
    ROLE_DELETE_SUCCESS: 'Relation role deleted successfully',
    ROLE_DELETE_FAILED: 'Failed to delete relation role',

    LEAD_SOURCE_CREATE_SUCCESS: 'Lead Source created successfully',
    LEAD_SOURCE_CREATE_FAILED: 'Failed to create Lead Source',
    LEAD_SOURCE_UPDATE_SUCCESS: 'Lead Source updated successfully',
    LEAD_SOURCE_UPDATE_FAILED: 'Failed to update Lead Source',
    LEAD_SOURCE_DELETE_SUCCESS: 'Lead Source deleted successfully',
    LEAD_SOURCE_DELETE_FAILED: 'Failed to delete Lead Source',

    TEXTSNIPPET_CREATE_SUCCESS: 'Text snippet created successfully',
    TEXTSNIPPET_UPDATE_SUCCESS: 'Text snippet updated successfully',
    TEXTSNIPPET_DELETE_SUCCESS: 'Text snippet deleted successfully',
    TEXTSNIPPET_CREATE_FAILED: 'Failed to create Text snippet',
    TEXTSNIPPET_UPDATE_FAILED: 'Failed to update Text snippet',
    TEXTSNIPPET_DELETE_FAILED: 'Failed to delete Text snippet',

    PROFILE_IMG_SUCCESS: 'Profile Image successfully uploaded',
    PROFILE_IMG_FAILED: 'Failed to upload Profile Image',
    PROFILE_IMG_VALIDATION1: 'No file selected!',
    PROFILE_IMG_VALIDATION2: 'Invalid file type. Allowed types are: .jpeg, .png, .gif',
    PROFILE_IMG_VALIDATION3: 'File is too small. Minimum size is 50KB.',

    JOB_TITLE_CREATED: 'Job Title created successfully',
    JOB_TITLE_DELETED: 'Job Title deleted successfully',
    JOB_TITLE_UPDATED: 'Job Title updated successfully',
    JOB_TITLE_CREATED_FAILED: 'Failed to create Job Title.',
    JOB_TITLE_DELETED_FAILED: 'Failed to delete Job Title.',
    JOB_TITLE_UPDATED_FAILED: 'Failed to update Job Title.',

    GLOBAL_TAG_CREATED: 'Global Tag created successfully',
    GLOBAL_TAG_DELETED: 'Global Tag deleted successfully',
    GLOBAL_TAG_UPDATED: 'Global Tag updated successfully',
    GLOBAL_TAG_CREATED_FAILED: 'Failed to create Global Tag.',
    GLOBAL_TAG_DELETED_FAILED: 'Failed to delete Global Tag.',
    GLOBAL_TAG_UPDATED_FAILED: 'Failed to update Global Tag.',

    BUSINESS_ROLE_CREATED: 'Business Role created successfully',
    BUSINESS_ROLE_DELETED: 'Business Role deleted successfully',
    BUSINESS_ROLE_UPDATED: 'Business Role updated successfully',
    BUSINESS_ROLE_CREATED_FAILED: 'Failed to create Business Role.',
    BUSINESS_ROLE_DELETED_FAILED: 'Failed to delete Business Role.',
    BUSINESS_ROLE_UPDATED_FAILED: 'Failed to update Business Role.',

    EXPERT_CREATED_SUCCESS: 'Expert created successfully',
    EXPERT_DELETED_SUCCESS: 'Expert deleted successfully',
    EXPERT_UPDATED_SUCCESS: 'Expert updated successfully',
    EXPERT_CREATED_FAILED: 'Failed to create Expert',
    EXPERT_DELETED_FAILED: 'Failed to delete Expert',
    EXPERT_UPDATED_FAILED: 'Failed to update Expert',

    NOTE_SUCCESS: 'Case Note is created successfully',
    NOTE_FAILED: 'Failed to create case note',

    ADD_CARD_SUCCESS: 'Card added successfully',
    DELETE_CARD_SUCCESS: 'Card deleted successfully',
    ADD_CARD_FAILED: 'Failed to add Card',
    DELETE_CARD_FAILED: 'Failed to delete Card',
    PLAN_SUBSCRIBE_SUCCESS: 'Plan subscribed successfully',
    PLAN_SUBSCRIBE_FAILED: 'Failed to subscribe the Plan',
    FORM_DATA_MISSING: 'Please fill all required fields in the form',
    DEFAULT_CARD_SUCCESS: 'Default Card changed successfully',
    DEFAULT_CARD_FAILED: 'Failed to update default card',
    CANCEL_SUBSCRIPTION_SUCCESS: 'Subscription will automatically be cancelled at the end of current period',
    CANCEL_SUBSCRIPTION_FAILED: 'Failed to cancel the subscription',
    TEMPLATE_FILE_SELECT_CASE: 'Select a case to save as document.',
    TEMPLATE_FILE_NAME: 'Document name is mandatory',
    DUPLICATE_CARD_EXISTS: 'Duplicate card exists',

    //priotity change messages
    PRIORITY_UPDATED_SUCCESSFULLY: 'Priority updated successfully',
    PRIORITY_UPDATED_FAILED: 'Failed to update Priority',

    MEDICAL_PROVIDER_CREATED: 'Medical Provider Created',
    VENDOR_CREATED: 'Vendor Created',
    VENDOR_REQUIRED: 'Vendor Required',
    MEDICAL_PROVIDER_REQUIRED: 'Medical Provider Required',

    //outcome
    // CASE_OUTCOME_CREATED
    CASE_OUTCOME_UPDATED: 'Case Outcome updated successfully',
    CASE_OUTCOME_UPDATED_FAILED: 'Failed to update Case Outcome',

    CASE_OUTCOME_DELETED: 'Case Outcome deleted successfully',

    CASE_OUTCOME_CREATED: 'Case Outcome created successfully',
    CASE_OUTCOME_CREATED_FAILED: 'Failed to create Case Outcome',

    LEAD_CLOSURE_REASON_DELETED: 'Lead Close Reason deleted successfully',
    CASE_CLOSURE_REASON_DELETED: 'Case Close Reason deleted successfully',

    LEAD_CLOSURE_UPDATED: 'Lead Close Reason updated successfully',
    LEAD_CLOSURE__UPDATED_FAILED: 'Failed to update Lead Close Reason',

    CASE_CLOSURE_UPDATED: 'Case Close Reason updated successfully',
    CASE_CLOSURE__UPDATED_FAILED: 'Failed to update Case Close Reason',

    LEAD_CLOSURE_CREATED: 'Lead Close Reason created successfully',
    LEAD_CLOSURE__CREATED_FAILED: 'Failed to create Lead Close Reason',

    CASE_CLOSURE_CREATED: 'Case Close Reason created successfully',
    CASE_CLOSURE__CREATED_FAILED: 'Failed to create Case Close Reason',

    DUPLICATE_TASK_STAGE: 'Duplicate Task Stage created successfully ',

    // LINK_COPY_FAIL: 'Failed to copy link',

    //File Url Copy
    CLIPBOARD_COPY_SUCCCESS: 'Link copied successfully',
    CLIPBOARD_COPY_FAIL: 'Failed to copy link',

    //File Download
    FILE_DOWNLOAD_FAIL: 'Failoed to download file',

    //Kpi Goals
    KPI_GOAL_CREATE_SUCCESS: 'KPI goal created successfully',
    KPI_GOAL_CREATE_FAILURE: 'Failed to create KPI goal',
    RESET_KPI_GOAL_SUCCESS: 'KPI goal resetted',
    RESET_KPI_GOAL_SUCCESS_FAIL: 'Failed to reset KPI goal',
    USER_KPI_TRACKING_SUCCESS: 'KPI tracking updated successfully',
    USER_KPI_TRACKING_FAIL: 'Failed to update KPI tracking',

    //Shared File Activity Logs
    LOAD_ACTIVITY_LOGS_FAILED: 'Failed to load activity logs',

    //nps
    NPS_SETTINGS_ADDED: 'NPS settings added successfully',
    NPS_SETTINGS_FAILED: 'Failed to add NPS settings',
    NPS_SCORE_SAVE_FAILED: 'Failed to save NPS form',
    NPS_SURVEY_SENT: 'NPS survey sent successfully',
    NPS_SURVEY_FAILED: 'Failed to send NPS survey',
  },

  label: {
    OK: 'OK',
    CANCEL: 'Cancel',
  },
};
