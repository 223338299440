import { useEffect, useState } from 'react';
import { getAuthURIService, getDisconnectQB, quickBooksCallback } from 'services/quickbooks';
import { getQueryParams } from 'utils/utils';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { confirmDialog } from 'primereact/confirmdialog';

var pollOAuth = null;

export default function useQuickBooks() {
  const [authdata, setAuthdata] = useState('');
  const [loadingURI, setloadingURI] = useState(true);
  const { addToast } = useToast();

  const getAuthURI = () => {
    setloadingURI(true);
    getAuthURIService().then((res) => {
      setAuthdata(res.data);
      setloadingURI(false);
    });
  };

  const openQuickbooksWindow = () => {
    if (authdata?.is_qb_connected) {
      return disConnectQB();
    }
    if (!authdata?.auth_url) {
      addToast(
        true,
        toastConstant.toasterType.ERROR,
        toastConstant.api.FAILED,
        'Unable to connect with Quickbooks. Please contact support.'
      );
      return;
    }
    // Launch Popup using the JS window Object
    var parameters = 'location=1,width=800,height=650';
    parameters += ',left=' + (window.screen.width - 800) / 2 + ',top=' + (window.screen.height - 650) / 2;
    var win = window.open(authdata?.auth_url, 'connectPopup', parameters);
    pollOAuth = window.setInterval(function () {
      try {
        if (win.document.URL.indexOf('code') != -1) {
          window.clearInterval(pollOAuth);
          const params = getQueryParams(win.document.URL);
          quickBooksCallback({
            qb_realm_id: params?.realmId,
            qb_state: params?.state,
            qb_auth_code: params?.code,
          })
            .then(() => {
              getAuthURI();
              win.close();
            })
            .catch((err) => {
              console.log(err);
              addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Error occured. Please try gain.');
            });
        }
      } catch (e) {
        console.log(e);
      }
    }, 100);
  };

  const disConnectQB = () => {
    confirmDialog({
      message: 'Are you sure you want to disconnect Quickbooks?',
      header: 'Disconnect Quickbooks',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloadingURI(true);
        getDisconnectQB()
          .then((res) => {
            getAuthURI();
            setAuthdata((preval) => ({ ...preval, is_qb_connected: false }));
          })
          .catch((err) => {
            console.log(err);
            setloadingURI(false);
          });
      },
      reject: () => {},
    });
  };

  useEffect(() => {
    getAuthURI();
    return () => {
      pollOAuth && window.clearInterval(pollOAuth);
    };
  }, []);

  return { loadingURI, authdata, openQuickbooksWindow, getAuthURI, disConnectQB };
}
