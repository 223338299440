import React from 'react';
import { Button } from 'primereact/button';
import useQuickBooks from './hooks/useQuickBooks';
import SettingsLayout from '../SettingsLayout/SettingsLayout';

function QuickbooksAuthPage() {
  const { loadingURI, authdata, openQuickbooksWindow } = useQuickBooks();

  return (
    <div>
      <h3>Quickbooks</h3>
      <br />
      <Button
        disabled={!authdata?.is_qb_connected && !authdata?.auth_url}
        label={authdata?.is_qb_connected ? 'Disconnect QuickBooks' : 'Connect to QuickBooks'}
        className="p-button p-button-primary"
        loading={loadingURI}
        onClick={openQuickbooksWindow}
      />
    </div>
  );
}

const QuickbooksAuth = () => {
  return (
    <SettingsLayout>
      <QuickbooksAuthPage />
    </SettingsLayout>
  );
};
export default QuickbooksAuth;
