import { Switch, Route } from 'react-router-dom';
import NpsForm from './NpsForm';

function NpsRouter() {
  return (
    <Switch>
      <Route exact path={`/nps`} component={NpsForm} />
    </Switch>
  );
}

export default NpsRouter;
