import React, { useContext, useEffect, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { generateFullName } from 'utils/generateFullNameUtils';
import { findArrayDifferences } from 'utils/utils';
import { UserDetailsContext } from 'context/userDetailsContext';
import LabelName from 'components/UI/LabelName/LabelName';
import SelectedAttendees from './SelectedAttendees';
import SelectedGroups from './SelectedGroups';
import SelectedFirmUsers from './SelectedFirmUsers';
import SuggestedTime from 'modules/calendar/Components/SuggestedTime/SuggestedTime';
import useEventFreeTime from 'hooks/useEventFreeTime';
import { convertToTimezoneFormat, currentBrowserTimeZone } from 'utils/utility_functions/timezone';

function EventAttendees({ control, getValues, setValue, trigger, selectedcase }) {
  const op = useRef(null);
  const { userList, editEventModal, eventDetails, setShowUserFilter, getUserList } = useCalendarContext();
  const userDetails = useContext(UserDetailsContext);
  const { eventFreeTime, loadFreeTime, loading } = useEventFreeTime();
  const history = useHistory();

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const {
    fields: groupsFields,
    insert: groupsInsert,
    remove: groupsRemove,
  } = useFieldArray({
    control,
    name: 'groups',
  });

  const {
    fields: firm_UsersFields,
    insert: firm_UsersInsert,
    remove: firm_UsersRemove,
  } = useFieldArray({
    control,
    name: 'firm_users',
  });

  useEffect(() => {
    if (Boolean(selectedcase)) {
      getUserList(selectedcase.id);
    }
  }, [selectedcase]);

  useEffect(() => {
    // to add logged user in the attendees array
    if (!editEventModal && userList) {
      setValue('selected_attendees_list', [userDetails?.firmDetails?.current_user]);
      handleAttendeesChange([userDetails?.firmDetails?.current_user]);
    }
    setShowUserFilter(true);
  }, [userList]);

  useEffect(() => {
    //PrimeReactMultiSelect: Empty label chips showing.
    const dropdownClasses = document?.querySelectorAll('.p-multiselect-token');
    for (const dropdownElement of dropdownClasses) {
      const dropLabelElement = dropdownElement?.querySelector('.p-multiselect-token-label')?.innerHTML;

      if (dropLabelElement?.trim()?.length === 0) {
        dropdownElement.style.display = 'none';
      }
    }
    if (!Boolean(editEventModal) && selectedcase?.id) {
      handleAttendeesChange([]);
      setValue('selected_attendees_list', []);
    }
  }, [userList]);

  function handleAttendeesChange(value) {
    const usersArray = userList?.find((item) => item.label === 'Contacts')?.items;
    const groupsArray = userList?.find((item) => item.label === 'Groups')?.items;
    const firm_usersArray = userList?.find((item) => item.label === 'Firm Users')?.items;

    const selectedFirmUsersArray = firm_usersArray?.filter((item) => value.includes(item.client_cognito_username ?? item.contact_id));

    const selectedUsersArray = usersArray?.filter((item) => value.includes(item.client_cognito_username ?? item.contact_id));

    const selectedGroupsArray = groupsArray?.filter((item) => value.includes(item.user_group_id));

    if (selectedUsersArray?.length > 0) {
      // Add new users to the userList
      selectedUsersArray?.forEach((currentSelection, index) => {
        const userExists = fields.some(
          (field) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!userExists) {
          insert(fields.length + index, {
            name: generateFullName(currentSelection),
            email: currentSelection.email,
            is_attendance_required: 'optional',
            send_reminder: true,
            cognito_username: currentSelection.client_cognito_username
              ? currentSelection.client_cognito_username
              : currentSelection.cognito_username,
            contact_id: currentSelection.contact_id ? currentSelection.contact_id : '',
            access_level: currentSelection?.access_level,
            is_individual_user: true,
            profile_image: currentSelection?.profile_image,
          });
        }
      });

      // Remove users from the userList
      fields.forEach((field, index) => {
        const userExists = selectedUsersArray.some(
          (currentSelection) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!userExists) {
          remove(index);
        }
      });
    } else if (selectedUsersArray?.length === 0) {
      remove();
    }

    if (selectedFirmUsersArray?.length > 0) {
      // Add new users to the userList
      selectedFirmUsersArray?.forEach((currentSelection, index) => {
        const firm_userExists = firm_UsersFields.some(
          (field) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!firm_userExists) {
          firm_UsersInsert(firm_UsersFields.length + index, {
            name: generateFullName(currentSelection),
            email: currentSelection.email,
            is_attendance_required: 'optional',
            send_reminder: true,
            cognito_username: currentSelection.client_cognito_username
              ? currentSelection.client_cognito_username
              : currentSelection.cognito_username,
            contact_id: currentSelection.contact_id ? currentSelection.contact_id : '',
            access_level: currentSelection?.access_level,
            is_individual_user: true,
            profile_image: currentSelection?.profile_image,
            firm_user_id: currentSelection.firm_user_id ? currentSelection.firm_user_id : '',
          });
        }
      });

      // Remove users from the userList
      firm_UsersFields.forEach((field, index) => {
        const firm_userExists = selectedFirmUsersArray.some(
          (currentSelection) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!firm_userExists) {
          firm_UsersRemove(index);
        }
      });
    } else if (selectedFirmUsersArray?.length === 0) {
      firm_UsersRemove();
    }

    if (!editEventModal && selectedGroupsArray?.length > 0) {
      selectedGroupsArray.forEach((currentSelection, index) => {
        const groupExists = groupsFields.some((field) => field.group_id === currentSelection.user_group_id);
        if (!groupExists) {
          groupsInsert(groupsFields.length + index, {
            group_id: currentSelection?.user_group_id,
            group_name: currentSelection?.group_name,
            sk: currentSelection?.sk,
            is_attendance_required: 'optional',
            send_reminder: true,
          });
        }
      });
      // Remove users from the userList
      groupsFields.forEach((field, index) => {
        const groupExists = selectedGroupsArray.some((currentSelection) => field.group_id === currentSelection.user_group_id);
        if (!groupExists) {
          groupsRemove(index);
        }
      });
    }
    if (selectedGroupsArray?.length === 0) {
      groupsRemove();
    }
  }

  function onChangeAttendeesWhileEditing(e) {
    if (editEventModal && eventDetails) {
      const oldArray = getValues('selected_attendees_list') || [];
      const guests = getValues('guests') || [];
      const groups = getValues('groups') || [];
      const { added, removed } = findArrayDifferences(oldArray, e.value);

      const contacts = userList?.reduce((arr, val) => [...arr, ...val.items], []);

      if (added?.[0]) {
        const addedContact = contacts?.find(
          (v) => (v?.client_cognito_username ?? v.contact_id) === added?.[0] || v?.user_group_id?.includes(added?.[0])
        );

        if (addedContact?.user_group_id) {
          const { group_name, user_group_id, sk } = addedContact;

          let updatedGroups = [
            ...groups,
            {
              group_name,
              group_id: user_group_id,
              sk: sk,
              is_attendance_required: 'optional',
              send_reminder: true,
            },
          ];
          setValue('groups', updatedGroups);
        } else if (addedContact?.contact_id || addedContact?.client_cognito_username) {
          const contactIndex = guests?.findIndex(
            // (c) => c?.contact_id === addedContact?.contact_id && c.cognito_username === addedContact?.client_cognito_username
            (c) => c?.cognito_username === addedContact?.client_cognito_username || c?.contact_id === addedContact?.contact_id
          );

          if (contactIndex !== -1) {
            setValue(
              'guests',
              guests.map((c, i) => {
                if (i === contactIndex) {
                  return { ...c, is_individual_user: true };
                } else return c;
              })
            );
          } else {
            const {
              contact_id,
              email,
              access_level,
              client_cognito_username,
              firm_user_id,
              is_attendance_required,
              send_reminder,
              profile_image,
            } = addedContact;
            setValue('guests', [
              ...guests,
              {
                name: generateFullName(addedContact),
                contact_id,
                cognito_username: client_cognito_username,
                email,
                access_level,
                is_individual_user: true,
                firm_user_id: firm_user_id,
                is_attendance_required: is_attendance_required,
                send_reminder: send_reminder,
                profile_image: profile_image,
              },
            ]);
          }
        }
      }
      if (removed?.[0]) {
        const removedContact = contacts?.find(
          (v) => (v?.client_cognito_username ?? v.contact_id) === removed?.[0] || v?.user_group_id?.includes(removed?.[0])
        );
        if (removedContact?.user_group_id) {
          let updatedGroups = groups?.filter((g) => g.group_id !== removedContact.user_group_id);
          setValue('groups', updatedGroups);
        } else if (removedContact?.contact_id || removedContact?.client_cognito_username) {
          const newArray = [];

          guests.forEach((item) => {
            if (
              (item?.contact_id === removedContact?.contact_id || item.cognito_username === removedContact?.client_cognito_username) &&
              !!item?.is_group_user === false
            ) {
              // Skip this item, effectively removing it
              return;
            }

            if (
              (item?.contact_id === removedContact?.contact_id || item.cognito_username === removedContact?.client_cognito_username) &&
              !!item?.is_group_user === true
            ) {
              newArray.push({
                ...item,
                is_individual_user: false,
              });
            } else newArray.push(item);
          });
          setValue('guests', newArray);
        }
      }
    }
  }

  const assignToItemTemplate = (v) => {
    if (v?.status === 'ARCHIVED' && (v?.firm_user_id || !v.contact_id)) {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.label} (Inactive)`}
        </>
      );
    }
    if ((v?.firm_user_id || !v.contact_id) && v?.is_confirmed === false) {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.label} (Unconfirmed)`}
        </>
      );
    }
    if ((v?.access_level === 'client' || v?.access_level === undefined || v.contact_id) && v?.res_type !== 'user-group') {
      if (!Boolean(v?.email)) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.label} (Email does not exist)`}
          </>
        );
      }
      if (v?.is_create_client_portal === false) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.label} (Disabled Lawft Access)`}
          </>
        );
      }
      if (v?.is_confirmed === false) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.label} (Unconfirmed)`}
          </>
        );
      }
    }
    return (
      <>
        {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {v?.label}
      </>
    );
  };

  const checkOptionDisabled = (v) => {
    if (v?.status === 'ARCHIVED' && (v?.firm_user_id || !v.contact_id)) {
      return true;
    }
    if ((v?.firm_user_id || !v.contact_id) && v?.is_confirmed === false) {
      return true;
    }
    if ((Boolean(v?.access_level) === false || v?.access_level === 'client' || v.contact_id) && v?.res_type !== 'user-group') {
      return !Boolean(v.email) || v.is_create_client_portal === false || v?.is_confirmed === false ? true : false;
    }
    return false;
  };

  const selecteduserList = getValues('selected_attendees_list');

  const getSuggestedTime = async (e) => {
    const startDate = new Date(getValues('when'));
    const meeting_start_time = new Date(getValues('meeting_start_time'));
    const meeting_end_time = new Date(getValues('meeting_end_time'));

    let start_date;

    const today = moment().tz(userDetails?.userDetails?.timezone).startOf('day');
    if (moment(startDate).isSame(today, 'day')) {
      start_date = moment().tz(userDetails?.userDetails?.timezone);
    } else {
      start_date = moment(startDate).startOf('day').toDate();
    }

    await loadFreeTime({
      user_list: selecteduserList,
      start_date: convertToTimezoneFormat(start_date, userDetails?.userDetails?.timezone),
      end_date: convertToTimezoneFormat(moment(start_date).add(2, 'days').endOf('day'), userDetails?.userDetails?.timezone),
      meeting_start_time: convertToTimezoneFormat(meeting_start_time, userDetails?.userDetails?.timezone),
      meeting_end_time: convertToTimezoneFormat(meeting_end_time, userDetails?.userDetails?.timezone),
      response_limit: 5,
    });
    if (op.current) op.current.toggle(e);
  };

  const handleSelectTime = (value) => {
    const offset1 = moment.tz(value, currentBrowserTimeZone).utcOffset();
    const offset2 = moment.tz(value, userDetails?.userDetails?.timezone).utcOffset();
    // Calculate the difference in minutes
    const differenceInMinutes = offset1 - offset2;
    const date = moment(value).subtract(differenceInMinutes, 'minutes').toDate();

    setValue('when', date);
    setValue('meeting_start_time', date);
    setValue('meeting_end_time', moment(date).tz(userDetails?.userDetails?.timezone).add(1, 'hour').toDate());
    trigger('when');
    trigger('meeting_start_time');
    trigger('meeting_end_time');
    op.current?.hide();
  };

  return (
    <>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="selected_attendees_list">
            Add Attendees
          </LabelName>
        </div>
        <div className="col-md-8 col-12 add-attendees">
          <Controller
            name="selected_attendees_list"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <MultiSelect
                options={userList ?? []}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                display="chip"
                className="input-shadow w-100"
                filter
                id={field?.name}
                value={userList?.length > 0 && field?.value}
                placeholder="Select Attendees"
                onChange={(e) => {
                  onChangeAttendeesWhileEditing(e);
                  field.onChange(e.value);
                  handleAttendeesChange(e.value);
                }}
                emptyFilterMessage="No records found"
                optionDisabled={!editEventModal ? checkOptionDisabled : []}
                itemTemplate={assignToItemTemplate}
                showSelectAll={false}
              />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          label="Suggest Time"
          className="btn-outline p-button-secondary outline"
          onClick={getSuggestedTime}
          aria-controls="popup_menu"
          aria-haspopup
          loading={loading}
          disabled={selecteduserList?.length < 2}
          tooltip="Suggested time will be within business hours, in 1-hour intervals."
          tooltipOptions={{ position: 'top', showDelay: 500 }}
        />
        <SuggestedTime {...{ handleSelectTime, eventFreeTime, op, timezone: userDetails?.userDetails?.timezone }} />
      </div>
      {userList?.length > 0 && groupsFields?.length > 0 && (
        <SelectedGroups selectedGroups={groupsFields} control={control} history={history} />
      )}
      {userList?.length > 0 && firm_UsersFields?.length > 0 && (
        <SelectedFirmUsers selectedAttendees={firm_UsersFields} control={control} history={history} />
      )}
      {userList?.length > 0 && fields?.length > 0 && <SelectedAttendees selectedAttendees={fields} control={control} history={history} />}
    </>
  );
}

export default EventAttendees;
