import apiEndPoints from 'common/apiEndPoints';
import { performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const saveNpsScore = (obj) => {
  let { token, data } = obj;

  return performRequestThird(apiEndPoints.methodType.PUT, `${apiEndPoints.saveNpsScore}?token=${token}`, data, null, false);
};

export const npsSettings = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const { interval, is_enabled, case_closure } = data;

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.npsSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&interval=${interval}&is_enabled=${is_enabled}&case_closure=${case_closure}`
  );
};

export const getNpsSettings = () => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(apiEndPoints.methodType.GET, `${apiEndPoints.getNpsSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const forceSendNps = (contactId) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.forceSendNps}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contactId}`
  );
};
