import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';

import LabelName from 'components/UI/LabelName/LabelName';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function NotLinkedToCase({ control, resetField }) {
  const { selectedItem, versionFileUpload } = useFileManagerContext();
  const isSelected = selectedItem?.case_id === 'undefined';
  return (
    <>
      {!versionFileUpload || (versionFileUpload && (selectedItem.case_id === 'undefined' || !selectedItem.case_id)) ? (
        <div className="not-liked-to-case-wrapper d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12">
            <Controller
              name="not_linked_to_case"
              control={control}
              defaultValue={isSelected}
              render={({ field }) => (
                <Checkbox
                  id={field.name}
                  value={field.value}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    e.target.checked && resetField('case');
                  }}
                  // disabled={versionFileUpload}
                  // disabled={!isAtHomeFolder && (!caseId || caseId === 'undefined')}
                  disabled={isSelected}
                />
              )}
            />
            {
              <LabelName required={false} htmlFor="not_linked_to_case" className="ms-2">
                Not Linked To Case/Lead
              </LabelName>
            }
          </div>
        </div>
      ) : null}
    </>
  );
}

export default NotLinkedToCase;
