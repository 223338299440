import { performRequest, performRequestSecond } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { Storage, Auth } from 'aws-amplify';
import { getTenantDetails } from 'utils/utils';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const createFile = (data, is_lead = false, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getFile = (params, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}${params}`
  );
};

export const updateFile = (data, is_lead = false, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getSignedFile = async (entityFileInfo) => {
  let url = entityFileInfo.file_path.split('/');
  // url.shift();
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  let user = await Auth.currentAuthenticatedUser();
  let userId = null;
  try {
    userId = user.attributes.sub;
    return await Storage.get(`${url.join('/')}`, { download: true, useAccelerateEndpoint: true });
  } catch (err) {
    /*  console.log(err) */
  }
};

export const getSignedUrl = async (entityFileInfo) => {
  let url = entityFileInfo.file_path.split('/');
  url.shift();
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  let user = await Auth.currentAuthenticatedUser();
  let userId = null;
  try {
    userId = user.attributes.sub;
    return await Storage.get(`${url.join('/')}`, { useAccelerateEndpoint: true });
  } catch (err) {
    /*  console.log(err) */
  }
};

export const getFolders = (params) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.file}/folders?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100&${params}`
  );
};

export const getFileClients = (case_id = '') => {
  const tenantId = cookie.get('tenantId') || null;
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.fileClients}?tenant_id=${tenantId}&case_id=${case_id}`
  );
};

export const sendESigantureRequest = (case_id, file_pk, file_sk, data, is_lead, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  //const tenant_sk = cookie.get('tenantSk') || null;
  //let tenantSk = tenant_sk ? tenant_sk.replace("#", "%23") : "";
  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  //as an object {}
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.sendESignature}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data,
    timeEntryData
  );
};

export const getFileDetails = (file_pk, file_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let filePK = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSK = file_sk ? file_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.getFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${filePK}&sk=${fileSK}`
  );
};

export const sendESigantureResponse = (case_id, file_pk, file_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  //const tenant_sk = cookie.get('tenantSk') || null;
  //let tenantSk = tenant_sk ? tenant_sk.replace("#", "%23") : "";
  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  //as an object {}
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.sendESignatureToAdmin}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}`
  );
};

export const fileTag = (data, is_lead = false, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.fileManager.tagFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getPresignedURL = (file_path, size = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getPresignedURL}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${file_path}&file_size=${size}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const presignedURLGet = (file_path, version_id = false, is_profile_image = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.presignedURLGet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${encodeURI(file_path)}${
    version_id ? `&version_id=${version_id}` : ''
  }${is_profile_image ? `&is_profile_image=${is_profile_image}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const shareFile = (case_id, file_pk, file_sk, data, is_lead, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  //as an object {}
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.fileSharing}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data,
    timeEntryData
  );
};

export const getTemplates = (params) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestSecond(apiEndPoints.methodType.GET, `/templates?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100${params}`);
};
