import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { isCurrentDatePastExpiry } from 'utils/utility_functions/isCurrentDatePastExpiry';

const FileSharingDetailsCard = ({ rowData }) => {
  const userContext = useUserDetailsContext();

  return (
    <div className="file-sharing-details">
      <div className="shared-card-container">
        <Card className="shared-card">
          {rowData?.file_shared_contact_id?.length > 0 && (
            <div className="shared-with-client grey-section">
              <i className="fas fa-check-circle shared-icon"></i>
              <span className="shared-text">Shared with client</span>
            </div>
          )}
          {isCurrentDatePastExpiry(rowData?.lawft_link_expires_on, userContext?.userDetails?.timezone) === false && (
            <div className="grey-section pointer">
              <div className="text-bold black-600 mb-2">Lawft Link</div>

              <div className="downloads-views-section">
                <div className="info">
                  <div className="p-column-title">Downloads</div>
                  <div className="value">{rowData?.download_count ? rowData?.download_count : 0}</div>
                </div>
                <div className="info">
                  <div className="p-column-title">Views</div>
                  <div className="value">{rowData?.view_count ? rowData?.view_count : 0}</div>
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default FileSharingDetailsCard;
