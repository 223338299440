import React from 'react';
import { Controller } from 'react-hook-form';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';

function EventCaseName({ control, errors, notLinkedToCaseWatcher, isClientCalendar, editEventModal }) {
  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={!notLinkedToCaseWatcher} htmlFor="case">
          Case/Lead
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="case"
          control={control}
          rules={{
            required: !notLinkedToCaseWatcher ? true : false,
            validate: (value) => {
              return !notLinkedToCaseWatcher ? typeof value === 'object' : true;
            },
          }}
          render={({ field }) => (
            <CaseListDropDown
              isClient={isClientCalendar}
              value={field.value}
              onChange={(e) => {
                if (field.value !== e) {
                  field.onChange(e);
                }
              }}
              name="case"
              placeholder="Case/Lead Name"
              noOptionsMessage="No Cases/Leads Found"
              isClearable
              disabled={notLinkedToCaseWatcher || editEventModal}
            />
          )}
        />
      </div>
      <div className="offset-md-4">
        {errors?.case?.type === 'required' && <InputErrorMessage>Please select case/lead</InputErrorMessage>}
        {errors?.case?.type === 'validate' && <InputErrorMessage>Please select valid case/lead</InputErrorMessage>}
      </div>
    </div>
  );
}

export default EventCaseName;
