import { useEffect, useState } from 'react';
import { listExperts } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export function useExperts() {
  const [List, setList] = useState([]);

  const loadExperts = () => {
    totalCountEvent('expert_type').then((response) => {
      listExperts(response.data).then((res) => {
        setList(res.data?.experts);
      });
    });
  };

  useEffect(() => {
    loadExperts();
  }, []);

  return { expertList: List };
}
