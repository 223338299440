import React, { useEffect, useState } from 'react';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';

const VideoViewer = (props) => {
  const { item, onLoad } = props;
  const [isAudio, setIsAudio] = useState(false);

  useEffect(() => {
    const fileExtension = item?.file_name.split('.').pop().toLowerCase();
    setIsAudio(['mp3', 'wav', 'ogg', 'aac'].includes(fileExtension));
  }, [item]);

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}>
        <FileDownload
          rowData={props?.item}
          isFromFilePreviewModal={true}
          filePreviewModalIconClassName={'icon-save video-download p-2 pointer float-end h2 mb-0'}
        />
      </div>

      {isAudio ? (
        <audio src={item?.url} controls style={{ width: '90%' }} className="mx-audio" onLoadStart={onLoad} autoPlay />
      ) : (
        <video src={item?.url} controls style={{ height: '100%', width: '100%' }} className="mx-auto" onLoadStart={onLoad} autoPlay />
      )}
    </div>
  );
};

export default VideoViewer;
